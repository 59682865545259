import "@/styles/mobile/header_mobile.scss";
import { getAssets } from "@/utils/helpers";
import LangSwitcher from "./lang-switcher";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import useGAEventsTracker from "../../hooks/useGAEventsTracker.js";

const MobileHeader = ({ handleLangChange }) => {
  const { t } = useTranslation();
  const GAEventsTracker = useGAEventsTracker("Page Section");
  const ids = [
    "Home",
    "AboutUs",
    "Esports",
    "Lootbox",
    "WhyChoose",
    "Events",
    "Contact",
  ];
  const [navbar, showNav] = useState(false);
  const [subBar, showSubBar] = useState(false);

  useEffect(() => {
    if (navbar) {
      document.documentElement.style.overflow = "hidden";
    } else {
      // NOTE : clear the inline style for html tag. As unset overflow will have some overflowing issue for header.
      document.documentElement.style.cssText = "";
    }
  }, [navbar]);

  return (
    <header className="mobile">
      {!navbar ? (
        <div className="cont">
          <div className="mobile-logo">
            <a href="/">
              <img
                src={require("@/" + getAssets("header-logo.png", false, false))}
                alt="logo"
              />
            </a>
          </div>
          <div className="mobile-switch">
            <LangSwitcher handleLangChange={handleLangChange} />
          </div>
          <div className="hamburger" onClick={() => showNav(true)}>
            <img src={require("@/" + getAssets("menu.png", true))} alt="logo" />
          </div>
        </div>
      ) : (
        <div className="hamburger-menu">
          <div className="hamburger-menu-container">
            <div className="hamburger-btn" onClick={() => showNav(false)}>
              <span>
                <IoCloseSharp />
              </span>
            </div>
            <Link
              smooth
              to={`${ids[0]}`}
              spy
              offset={-10}
              onClick={() => {
                showNav(false);
                GAEventsTracker("Home Section");
              }}
            >
              {t("Home")}
            </Link>
            <Link
              smooth
              to={`${ids[1]}`}
              spy
              offset={-5}
              onClick={() => {
                showNav(false);
                GAEventsTracker("About Us Section");
              }}
            >
              {t("About")}
            </Link>
            {!subBar ? (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  showSubBar(true);
                }}
                className="menu-item"
              >
                {t("Products")}
                <span className="span-arrows">
                  <IoIosArrowUp />
                </span>
              </span>
            ) : (
              <div>
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    showSubBar(false);
                  }}
                  className="menu-item"
                >
                  {t("Products")}
                  <span className="span-arrows">
                    <IoIosArrowDown />
                  </span>
                </span>
                <div className="nav-sub">
                  <Link
                    smooth
                    to={`${ids[2]}`}
                    spy
                    offset={-1}
                    onClick={() => {
                      showNav(false);
                      GAEventsTracker("Esports Section");
                    }}
                  >
                    {t("Esports")}
                  </Link>
                  <Link
                    smooth
                    to={`${ids[3]}`}
                    spy
                    offset={10}
                    onClick={() => {
                      showNav(false);
                      GAEventsTracker("Lootbox Section");
                    }}
                  >
                    {t("Lootbox")}
                  </Link>
                </div>
              </div>
            )}

            <Link
              smooth
              to={`${ids[4]}`}
              spy
              offset={11}
              onClick={() => {
                showNav(false);
                GAEventsTracker("Why Choose Us Section");
              }}
            >
              {t("Why choose us")}
            </Link>
            <Link
              smooth
              to={`${ids[5]}`}
              spy
              offset={12}
              onClick={() => {
                showNav(false);
                GAEventsTracker("Events Section");
              }}
            >
              {t("Events")}
            </Link>
            <Link
              smooth
              to={`${ids[6]}`}
              spy
              offset={13}
              onClick={() => {
                showNav(false);
                GAEventsTracker("Contact Us Section");
              }}
            >
              {t("Contact us")}
            </Link>
            <div className="hamburger-img-bottom">
              <Link smooth to="/">
                <img
                  src={require("@/" +
                    getAssets("header-logo.png", false, false))}
                  alt="logo"
                />
              </Link>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default MobileHeader;
