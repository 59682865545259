import { Link } from "react-scroll";
import { getAssets } from "@/utils/helpers";

const HeaderLogo = () => {
  return (
    <Link
      className="clickable logo-container"
      smooth
      href="#Home"
      to={`Home`}
      spy
      offset={-70}
      ignoreCancelEvents={false}
    >
      <img
        onClick={() => {
          window.location.pathname = "/";
        }}
        src={require("@/" + getAssets("header-logo.png", false, false))}
        alt="logo"
      />
    </Link>
  );
};

export default HeaderLogo;
