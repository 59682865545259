import { Outlet } from "react-router-dom";
import Header from "@/components/header";
import Footer from "@/components/footer";
import ModalWarning from "@/components/modal";
import Float from "@/components/float";
import { useState } from "react";

import ReactGA from "react-ga";
ReactGA.initialize("UA-167085678-2");
const Layout = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const handleLangChange = () => {
    setModalIsOpen(true);
  };
  const [modalIsOpen, setModalIsOpen] = useState(true);
  return (
    <>
      <Header handleLangChange={handleLangChange} />
      <Outlet />
      <ModalWarning modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <Float />
      <Footer />
    </>
  );
};

export default Layout;
