import "@/styles/header.scss";
import HeaderLogo from "./logo.js";
import LangSwitcher from "./lang-switcher";
import Reveal from "react-awesome-reveal";
import { MENU_FADE_SLIDE_DOWN } from "@/utils/constant/keyframes";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import useGAEventsTracker from "../../hooks/useGAEventsTracker.js";
import Mobile from "./mobile.js";
import { useDimensionTracker } from "../../hooks/useDimensionsTracker.js";
import { useEffect } from "react";
import { getActiveLang } from "@/utils/helpers";

const Header = ({ handleLangChange }) => {
  useEffect(() => {
    // Disable browser scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    // Scroll to the top when the page reloads
    window.scrollTo(0, 0);

    return () => {
      // Restore scroll restoration to default when component unmounts
      window.history.scrollRestoration = 'auto';
    };
  }, []);

  const { t } = useTranslation();
  const ids = [
    "",
    "Home",
    "AboutUs",
    "Esports",
    "Lootbox",
    "WhyChoose",
    "Events",
    "Contact",
  ];
  const dimensions = useDimensionTracker();
  const GAEventsTracker = useGAEventsTracker("Page Section");

  return (
    <div>
      {dimensions > 1024 ? (
        <header>
          <nav>
            <ul className="nav-left">
              <li>
                <WrapSlide delay={200}>
                  <Link
                    smooth
                    href="#Home"
                    to={`${ids[1]}`}
                    spy
                    offset={-70}
                    ignoreCancelEvents={false}
                    onClick={(e) => {
                      GAEventsTracker("Home Section");
                    }}
                    
                  >
                    {t("Home")}
                  </Link>
                </WrapSlide>
              </li>
              <li>
                <WrapSlide delay={130}>
                  <Link
                    smooth
                    to={`${ids[2]}`}
                    href="#AboutUs"
                    spy
                    offset={-64}
                    ignoreCancelEvents={false}
                    onClick={(e) => {
                      GAEventsTracker("About Us Section");
                    }}
                  >
                    {t("About")}
                  </Link>
                </WrapSlide>
              </li>

              <div className={`product_link`}>
                <WrapSlide delay={50}>
                  <Link smooth to={`SpyProductGroup`} spy offset={-64} ignoreCancelEvents={false} className="product_group" onClick={(e)=>{
                    e.preventDefault();
                  }}>
                    {t("Products")}
                  </Link>
                  <ul
                    className={
                      getActiveLang() === "zh" ? "nav-sub-zh" : "nav-sub"
                    }
                  >
                    <li>
                      <Link
                        smooth
                        to={`${ids[3]}`}
                        href="#Esports"
                        spy
                        offset={-63}
                        ignoreCancelEvents={false}
                        onClick={(e) => {
                          GAEventsTracker("Esports Section");
                        }}
                        
                      >
                        {t("Esports")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        smooth
                        to={`${ids[4]}`}
                        href="#Lootbox"
                        ignoreCancelEvents={false}
                        spy
                        offset={-62}
                        onClick={(e) => {
                          GAEventsTracker("Lootbox Section");
                        }}
                        
                      >
                        {t("Lootbox")}
                      </Link>
                    </li>
                  </ul>
                </WrapSlide>
              </div>
            </ul>
            <HeaderLogo />
            <ul className="nav-right">
              <li>
                <WrapSlide delay={50}>
                  <Link
                    smooth
                    to={`${ids[5]}`}
                    href="#WhyChoose"
                    spy
                    offset={-61}
                    ignoreCancelEvents={false}
                    onClick={(e) => {
                      GAEventsTracker("Why Choose Us Section");
                    }}
                    
                  >
                    {t("Why choose us")}
                  </Link>
                </WrapSlide>
              </li>
              <li>
                <WrapSlide delay={130}>
                  <Link
                    smooth
                    to={`${ids[6]}`}
                    href="#Events"
                    ignoreCancelEvents={false}
                    spy
                    offset={-59}
                    onClick={(e) => {
                      GAEventsTracker("Events Section");
                    }}
                    
                  >
                    {t("Events")}
                  </Link>
                </WrapSlide>
              </li>
              <li>
                <WrapSlide delay={200}>
                  <Link
                    smooth
                    to={`${ids[7]}`}
                    href="#Contact"
                    ignoreCancelEvents={false}
                    spy
                    offset={-58}
                    onClick={(e) => {
                      GAEventsTracker("Contact Us Section");
                    }}
                    
                  >
                    {t("Contact us")}
                  </Link>
                </WrapSlide>
              </li>
            </ul>
            <ul className="nav-language">
              <li>
                <LangSwitcher handleLangChange={handleLangChange} />
              </li>
            </ul>
          </nav>
        </header>
      ) : (
        dimensions <= 1024 && <Mobile handleLangChange={handleLangChange} />
      )}
    </div>
  );
};

const WrapSlide = (props) => {
  return (
    <Reveal
      keyframes={MENU_FADE_SLIDE_DOWN}
      duration={1000}
      delay={props.delay}
      triggerOnce
    >
      {props.children}
    </Reveal>
  );
};

export default Header;
