import "@/styles/mobile/banner_mobile.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { MOBILE_BANNER } from "./info";
import { useTranslation } from "react-i18next";
import { getAssets } from "@/utils/helpers";

const MobileBanner = () => {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 1000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
  };
  return (
    <div id="banner" className="mobile_banner">
      <Slider {...settings}>
        {MOBILE_BANNER.map((items) => (
          <div key={items.id}>
            <div className="banner-wrapper">
              {items.id === 1 ? (
                <a
                  className="cert-area"
                  href="https://access.gaminglabs.com/Certificate/Index?i=391"
                  alt="GLC certified"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </a>
              ) : (
                ""
              )}
              <img
                src={require("@/" + getAssets(`${t(items.path)}`, true))}
                alt={t(items.title)}
                title={t(items.title)}
                className={items.class}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MobileBanner;
