import "@/styles/sections/services.scss";
import "@/styles/mobile/service_mobile.scss";
import { useTranslation } from "react-i18next";
import { getAssets, getActiveLang } from "@/utils/helpers";
import Reveal from "react-awesome-reveal";
import { FADE_TOP_BOTTOM, FADE_BOTTOM_TOP } from "@/utils/constant/keyframes";
import { SERVICES_1, SERVICES_2 } from "./info";
import Gallery from "../gallery";
import { useDimensionTracker } from "../../../hooks/useDimensionsTracker";

const Services = () => {
  const { t } = useTranslation();
  const dimensions = useDimensionTracker();
  return (
    <div id="WhyChoose" className="services">
      {dimensions > 600 ? (
        <div>
          <div className="services-wrap">
            <WrapSlide delay={500}>
              <div className="title g-heading">
                <h2 className="heading">{t("Why choose us")}</h2>
              </div>
            </WrapSlide>
            <div className="blocks">
              {SERVICES_1.map((items) => (
                <div className="block" key={items.id}>
                  <WrapSlide delay={items.delay}>
                    <div>
                      <img
                        src={require("@/" +
                          getAssets(items.path, false, false))}
                        alt="img"
                      />
                      <h3
                        className={`${
                          getActiveLang() === "zh" ? "text_margin" : ""
                        }`}
                      >
                        {t(items.title)}
                      </h3>
                      {items.id === 4 && getActiveLang() === "zh" ? (
                        <>
                          {t(items.desc_first)
                            .split(",")
                            .map((text1, index) => (
                              <div
                                key={index}
                                className={index === 1 ? "text-div-indent" : ""}
                              >
                                {text1 + (index === 0 ? ", " : "")}
                                {"\n"}
                              </div>
                            ))}
                        </>
                      ) : (
                        <p> {t(items.desc_first)}</p>
                      )}
                      <p>{t(items.desc_sec)}</p>
                    </div>
                  </WrapSlide>
                </div>
              ))}
              {SERVICES_2.map((items) => (
                <div className="block" key={items.id}>
                  <WrapSlide delay={items.delay} slideTop>
                    <div>
                      <img
                        src={require("@/" +
                          getAssets(items.path, false, false))}
                        alt="img"
                      />
                      <h3
                        className={`${
                          getActiveLang() === "zh" && items.id !== 8
                            ? "text_margin"
                            : ""
                        } ${
                          getActiveLang() === "zh" &&
                          items.id === 8 &&
                          "text-id-8"
                        }`}
                      >
                        {t(items.title)}
                      </h3>
                      {items.id !== 5 && getActiveLang() === "zh" ? (
                        <>
                          {t(items.desc_first)
                            .split(",")
                            .map((text2, index) => (
                              <div
                                key={index}
                                className={index === 1 ? "text-div-indent" : ""}
                              >
                                {text2 + (index === 0 ? ", " : "")}
                                {"\n"}
                              </div>
                            ))}
                        </>
                      ) : (
                        <p> {t(items.desc_first)}</p>
                      )}
                      <p className={items.id === 7 ? "text_width" : ""}>
                        {t(items.desc_sec)}
                      </p>
                    </div>
                  </WrapSlide>
                </div>
              ))}
            </div>
          </div>
          <Gallery />
        </div>
      ) : (
        dimensions <= 600 && (
          <div>
            <div className="services-wrap">
              <WrapSlide delay={500}>
                <div className="title g-heading">
                  <h2 className="heading">{t("Why choose us")}</h2>
                </div>
              </WrapSlide>
              <div className="blocks">
                {SERVICES_1.map((items) => (
                  <div className="block" key={items.id}>
                    <WrapSlide delay={items.delay}>
                      <div>
                        <img
                          src={require("@/" +
                            getAssets(items.path, false, false))}
                          alt="img"
                        />
                        <h3
                          className={`${
                            getActiveLang() === "zh" ? "text_margin" : ""
                          }`}
                        >
                          {t(items.title)}
                        </h3>
                        {items.id === 4 && getActiveLang() === "zh" ? (
                          <>
                            {t(items.desc_first)
                              .split(",")
                              .map((text1, index) => (
                                <div
                                  key={index}
                                  className={index === 1 ? "text-div-indent" : ""}
                                >
                                  {text1 + (index === 0 ? ", " : "")}
                                  {"\n"}
                                </div>
                              ))}
                          </>
                        ) : (
                          <p> {t(items.desc_first)}</p>
                        )}
                        <p>{t(items.desc_sec)}</p>
                      </div>
                    </WrapSlide>
                  </div>
                ))}
                {SERVICES_2.map((items) => (
                  <div className="block" key={items.id}>
                    <WrapSlide delay={items.delay}>
                      <div>
                        <img
                          src={require("@/" +
                            getAssets(items.path, false, false))}
                          alt="img"
                        />
                        <h3
                          className={`${
                            getActiveLang() === "zh" && items.id !== 8
                              ? "text_margin"
                              : ""
                          } ${
                            getActiveLang() === "zh" &&
                            items.id === 8 &&
                            "text-id-8"
                          }`}
                        >
                          {t(items.title)}
                        </h3>
                        {items.id !== 5 && getActiveLang() === "zh" ? (
                          <>
                            {t(items.desc_first)
                              .split(",")
                              .map((text2, index) => (
                                <div
                                  key={index}
                                  className={index === 1 ? "text-div-indent" : ""}
                                >
                                  {text2 + (index === 0 ? ", " : "")}
                                  {"\n"}
                                </div>
                              ))}
                          </>
                        ) : (
                          <p> {t(items.desc_first)}</p>
                        )}
                        <p className={items.id === 7 ? "text_width" : ""}>
                          {t(items.desc_sec)}
                        </p>
                      </div>
                    </WrapSlide>
                  </div>
                ))}
              </div>
            </div>
            <Gallery />
          </div>
        )
      )}
    </div>
  );
};

const WrapSlide = (props) => {
  const direction = props.slideTop ? FADE_BOTTOM_TOP : FADE_TOP_BOTTOM;
  return (
    <Reveal keyframes={direction} delay={props.delay} className="block-wrap">
      {props.children}
    </Reveal>
  );
};

export default Services;
