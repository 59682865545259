import "@/styles/content.scss";
import Banner from "@/components/sections/banner";
import AboutUs from "@/components/sections/aboutUs/index";
import Lootbox from "@/components/sections/lootbox/index";
import Exclusive from "@/components/sections/exclusive/index";
import Services from "@/components/sections/services";
import Contact from "../components/sections/contact";
import MarqueeImage from "../components/sections/marquee";
import Events from "../components/sections/events";
import { useLocation } from "react-router-dom";

const Home = () => {
  const { pathname } = useLocation();
  if (pathname === "?demo=true") return null;
  return (
    <div className="content-wrapper">
      <Banner />
      <AboutUs />
      <div id="SpyProductGroup">
        <Lootbox />
        <Exclusive />
      </div>
      <Services />
      <Events />
      <MarqueeImage />
      <Contact />
    </div>
  );
};

export default Home;
